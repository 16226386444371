import {
  ArrowPathRoundedSquareIcon,
  CameraIcon,
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { AlertTypes, RecordingTypes } from 'globals/enums';

export const TABS = {
  ALERT_LOGS: 'Alert Logs',
  CHAT: 'Chat',
  APPROVALS: 'Approvals',
  SUMMARY: 'Summary',
};

export const PLAYBACKTABS = {
  ALERT_LOGS: 'Alert Logs',
  CHAT: 'Chat',
  SUMMARY: 'Summary',
};

export const PHOTO_REJECTED_REASONS = [
  {
    key: "Photo ID doesn't match with the photo captured",
    value: "Photo ID doesn't match with the photo captured",
    text: "Photo ID doesn't match with the photo captured",
    verification_type: 'photo_id',
  },
  {
    key: 'Invalid ID card used for authentication',
    value: 'Invalid ID card used for authentication',
    text: 'Invalid ID card used for authentication',
    verification_type: 'photo_id',
  },
  {
    key: 'The photo on the ID card is not visible/blurred',
    value: 'The photo on the ID card is not visible/blurred',
    text: 'The photo on the ID card is not visible/blurred',
    verification_type: 'photo_id',
  },
];

export const AUTO_ROTATE_INTERVAL = 10000;

export const TEST_ENV_REJECT_REASONS = [
  {
    key: "Full 360° view is 'not' shown seamlessly without any part being skipped or hurried through",
    value:
      "Full 360° view is 'not' shown seamlessly without any part being skipped or hurried through",
    text: "Full 360° view is 'not' shown seamlessly without any part being skipped or hurried through",
    verification_type: 'test_env',
  },
  {
    key: 'Another computer is present in the room',
    value: 'Another computer is present in the room',
    text: 'Another computer is present in the room',
    verification_type: 'test_env',
  },
  {
    key: 'Another person present in the room, despite asking for the candidate to be alone',
    value: 'Another person present in the room, despite asking for the candidate to be alone',
    text: 'Another person present in the room, despite asking for the candidate to be alone',
    verification_type: 'test_env',
  },
  {
    key: 'The camera remained covered during check',
    value: 'The camera remained covered during check',
    text: 'The camera remained covered during check',
    verification_type: 'test_env',
  },
  {
    key: "Didn't show allowed materials on camera",
    value: "Didn't show allowed materials on camera",
    text: "Didn't show allowed materials on camera",
    verification_type: 'test_env',
  },
  {
    key: 'Deskphone not disconnected',
    value: 'Deskphone not disconnected',
    text: 'Deskphone not disconnected',
    verification_type: 'test_env',
  },
  {
    key: 'Clean whiteboard if present in the room',
    value: 'Clean whiteboard if present in the room',
    text: 'Clean whiteboard if present in the room',
    verification_type: 'test_env',
  },
  {
    key: 'Presence of mobile phone',
    value: 'Presence of mobile phone',
    text: 'Presence of mobile phone',
    verification_type: 'test_env',
  },
  {
    key: 'Shared desktop view',
    value: 'Shared desktop view',
    text: 'Shared desktop view',
    verification_type: 'test_env',
  },
  {
    key: 'Headsets or earphones present',
    value: 'Headsets or earphones present',
    text: 'Headsets or earphones present',
    verification_type: 'test_env',
  },
  {
    key: 'Improper lighting',
    value: 'Improper lighting',
    text: 'Improper lighting',
    verification_type: 'test_env',
  },
];

export const USER_ROLES = {
  CANDIDATE: 'candidate',
  PROCTOR: 'proctor',
  TEST_ADMIN: 'test-admin',
  ALVY: 'alvy',
};

export const ALERT_SEVERITY_COLOR_MAP = {
  critical: {
    primary: 'bg-red-500',
    secondary: 'bg-red-100',
  },
  high: {
    primary: 'bg-red-500',
    secondary: 'bg-red-100',
  },
  medium: {
    primary: 'bg-amber-500',
    secondary: 'bg-amber-100',
  },
  low: {
    primary: 'bg-yellow-500',
    secondary: 'bg-yellow-100',
  },
  none: {
    primary: 'bg-slate-500',
    secondary: 'bg-slate-100',
  },
};

export const mediaSettingsIcons = [
  { Icon: CameraIcon, title: 'Primary camera', value: RecordingTypes.Primary },
  { Icon: ComputerDesktopIcon, title: 'Screen recording', value: RecordingTypes.ScreenRecording },
  { Icon: DevicePhoneMobileIcon, title: 'Secondary camera', value: RecordingTypes.Secondary },
  { Icon: ArrowPathRoundedSquareIcon, title: 'Auto rotate', value: 'auto-rotate' },
];

export const flagReasons = [
  {
    key: 1,
    text: 'Window Focus-Out',
    alert_type_id: 5,
  },
  {
    key: 2,
    text: 'Face Not Detected',
    alert_type_id: 16,
  },
  {
    key: 3,
    text: 'Camera Not Detected',
    alert_type_id: 59,
  },
  {
    key: 4,
    text: 'Pasting text detected',
    alert_type_id: 128,
  },
  {
    key: 5,
    text: 'Camera switched',
    alert_type_id: 130,
  },
  {
    key: 6,
    text: 'Microphone switched',
    alert_type_id: 131,
  },
  {
    key: 7,
    text: 'Mobile phone detected',
    alert_type_id: 169,
  },
  {
    key: 8,
    text: 'Book detected',
    alert_type_id: 170,
  },
  {
    key: 9,
    text: 'Noise detected(beta)',
    alert_type_id: 210,
  },
  {
    key: 10,
    text: 'Multiple people detected',
    alert_type_id: 202,
  },
  {
    key: 11,
    text: 'Suboptimal ID check',
    alert_type_id: 228,
  },
  {
    key: 12,
    text: 'Suboptimal environment check',
    alert_type_id: 229,
  },
  {
    key: 13,
    text: 'External application',
    alert_type_id: 231,
  },
  {
    key: 14,
    text: "External help",
    alert_type_id: 232,
  },
  {
    key: 15,
    text: 'External tool',
    alert_type_id: 233,
  },
  {
    key: 16,
    text: 'Non-compliance',
    alert_type_id: 234,
  },
  {
    key: 17,
    text: 'Potential malpractice',
    alert_type_id: 235,
  },
  {
    key: 18,
    text: 'Suboptimal condition',
    alert_type_id: 236,
  },
  {
    key: 19,
    text: 'Technical issue - proctoring',
    alert_type_id: 238,
  },
  {
    key: 20,
    text: 'Technical issue - test delivery',
    alert_type_id: 239,
  },
  {
    key: 21,
    text: 'Successfully completed without any violation',
    alert_type_id: 240,
  },
  {
    key: 22,
    text: 'Needs investigation',
    alert_type_id: 241,
  },
  {
    key: 23,
    text: 'Test environment verified manually [environment check]',
    alert_type_id: 242,
  },
  {
    key: 24,
    text: 'Photo ID verified manually [photo ID check]',
    alert_type_id: 243,
  },
  {
    key: 25,
    text: 'Technical issue - secure browser',
    alert_type_id: 244,
  },
  {
    key: 26,
    text: 'Internet or hardware issue',
    alert_type_id: 245,
  },
];

export const debugAlertTypeIds: AlertTypes[] = [
  AlertTypes.ProctorRatingOverride,
  AlertTypes.SessionPlaybackUrl,
  AlertTypes.CronJobSessionCompleted,
  AlertTypes.TwilioCompositionEnqueued,
  AlertTypes.TwilioCompositionCompleted,
  AlertTypes.TwilioScreenCompositionEnqueued,
  AlertTypes.TwilioScreenCompositionCompleted,
  AlertTypes.TwilioSecondaryCameraCompositionEnqueued,
  AlertTypes.TwilioSecondaryCameraCompositionCompleted,
  AlertTypes.SecondaryCameraConnected,
  AlertTypes.SecondaryCameraDisconnected,
  AlertTypes.CandidateOnGoodBandwidth,
  AlertTypes.CandidateOnline,
  AlertTypes.UserClosedOrRefreshedProctoringSession,
  AlertTypes.TwilioCompositionFailed,
  AlertTypes.PrimaryCameraConnected,
  AlertTypes.PrimaryCameraDisconnected,
  AlertTypes.RoomCreated,
  AlertTypes.RoomEnded,
  AlertTypes.MultipleMonitorsDetected,
];

export const defaultAlertTypes: AlertTypes[] = [
  AlertTypes.SessionJoined,
  AlertTypes.SessionCompleted,
  AlertTypes.PrimaryCameraRecordingStarted,
  AlertTypes.PrimaryCameraRecordingStopped,
  AlertTypes.SecondaryCameraRecordingStarted,
  AlertTypes.SecondaryCameraRecordingStopped,
  AlertTypes.ScreenRecordingStarted,
  AlertTypes.ScreenRecordingStopped,
  AlertTypes.PrecheckApproved,
  AlertTypes.PrecheckRejected,
];

export const CUSTOM_FLAG_ALERT_TYPE_ID = '137';

export const playbackSettingIcons = {
  [RecordingTypes.Primary]: {
    Icon: VideoCameraIcon,
    title: 'Primary camera',
  },
  [RecordingTypes.ScreenRecording]: {
    Icon: ComputerDesktopIcon,
    title: 'Screen recording',
  },
  [RecordingTypes.Secondary]: {
    Icon: DevicePhoneMobileIcon,
    title: 'Secondary camera',
  },
};


/**
 * Environment for LiveKit.
 * @memberof EnvironmentVariable LIVEKIT_URL
 */
export const livekitWSUrl: string =
  process.env.LIVEKIT_URL || 'wss://test-app-u768aeqp.livekit.cloud';

export const FIREBASE_RELATIONS = {
  SESSION_V2: 'session_v2',
  SESSION: 'session',
  AUDIO_CALL: 'audio_call',
  ROOM: 'room',
  ROOM_PARTICIPANT: 'room_participant',
  PROCTOR_PRESENCE: 'proctor_presence',
};

export const SESSION_STATUS = {
  ONLINE: 'online',
  NO_SHOW: 'no_show',
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress',
  OFFLINE: 'offline',
  CREATED: 'created',
};
export const PRECHECK_STEPS = {
  TEST_ENV: 'test_env',
  PHOTO_ID: 'photo_id',
  FACE_CAPTURE: 'face_capture',
};
export const PRECHECK_STATUS = {
  SUBMITTED: 'submitted',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

export const ALERT_TYPE_IDS = {
  SESSION_JOINED: 3,
  SESSION_COMPLETED: 113,
  PRIMARY_CAMERA_RECORDING_STARTED: 198,
  PRIMARY_CAMERA_RECORDING_STOPPED: 199,
  SECONDARY_CAMERA_RECORDING_STARTED: 200,
  SECONDARY_CAMERA_RECORDING_STOPPED: 201,
  SCREEN_RECORDING_STARTED: 203,
  SCREEN_RECORDING_STOPPED: 204,
};

export const AUDIO_CALL_STATUS = {
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  REQUESTED: 'requested',
  INITIATED: 'initiated',
  STALE: 'stale',
};

export const PROVIEW_ADMIN_EVENT_URL = process.env.PROVIEW_ADMIN_EVENT_URL;

export const PROVIEW_EVENT_SCHEDULER_URL = process.env.PROVIEW_EVENT_SCHEDULER_URL;

export const PROVIEW_API_URL = process.env.PROVIEW_API_URL;

export const LMS_API_URL = process.env.LMS_API_URL;

export const LMS_ATTENDEE_FILENAME = (process.env.LMS_ATTENDEE_FILENAME || 'attendee') + '.csv';

export const gutterSize = 10;

export const cameraGrabListHeight = 175;

export const listItemSize = 200;

export const LMS_TYPE = {
  MOODLE: 'MOODLE',
  CANVAS: 'CANVAS',
  BLACKBOARD: 'BLACKBOARD',
  BRIGHTSPACE: 'BRIGHTSPACE',
  CUSTOM_AE: 'CUSTOM_AE',
};

export const PLAYBACK_SPEEDS_TWILIO = [8, 4, 2, 1];

export const PLAYBACK_SPEEDS_LIVEKIT = [2, 1]; // Livekit having problems playing video at 4x and 8x.

export const TINYMCE_LICENSE_KEY = 'gpl';
export const AI_ALERTS_LIST = [212, 213, 214, 215, 216, 217, 227, 246, 247];
